var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.tabs),function(item,i){return _c('v-tab',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs-items',{class:_vm.$vuetify.breakpoint.smAndDown ? 'px-0 mt-8' : 'pa-8',model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab-item',{staticClass:"d-flex"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-capitalize rounded-pill font-weight-bold",attrs:{"elevation":"0","color":_vm.color.secondary},on:{"click":function($event){(_vm.dialog = true), _vm.cleanForm()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Agregar Beneficio ")],1)],1),_vm._l((_vm.benefitsList),function(item,i){return _c('v-col',{key:i,attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown
              ? 12
              : _vm.$vuetify.breakpoint.lgAndDown
              ? 12
              : 4}},[_c('benefitsCard',{attrs:{"name":item.name,"description":item.description,"icon":item.icon},on:{"edit":function($event){return _vm.handleEdit(item)},"delete":function($event){(_vm.deleteSwitch = true), (_vm.dialog = true), (_vm.temporalId = item._id)}}})],1)})],2)],1)],1),_c('PersonalizedDialog',{attrs:{"dialog":_vm.dialogComputed,"hideImage":!_vm.deleteSwitch,"disableContinueButton":_vm.deleteSwitch ? false : !_vm.formValid},on:{"discard":function($event){(_vm.dialog = false), (_vm.deleteSwitch = false)},"save":function($event){_vm.editComputed
        ? _vm.editBenefit()
        : _vm.deleteSwitch
        ? _vm.deleteRegist(_vm.temporalId)
        : _vm.interStep()}},scopedSlots:_vm._u([{key:"alertName",fn:function(){return [_vm._v(" "+_vm._s(_vm.deleteSwitch ? "Eliminar beneficio." : "Crear nuevo beneficio")+" ")]},proxy:true},{key:"alertContent",fn:function(){return [(_vm.deleteSwitch)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Seguro que quieres eliminar los datos de este beneficio?")])]):_c('div',{staticClass:"d-flex justify-center flex-column"},[_c('p',{staticClass:"text-center ma-0 mb-4"},[_vm._v(" Carga aquí la información de tu nuevo beneficio. Recomendamos que las dimensiones de la imagen sean como mínimo de 250 x 250 pixeles ")]),_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},_vm._l((_vm.form),function(field){return _c('div',{key:field.id,staticClass:"text-center"},[(field.id == 'icon')?_c('ImageUploader',{attrs:{"imgUrl":field.value != null
                  ? field.value
                  : '@/assets/images/weChamber/wechamber-blue-logo-placeholder.png'},on:{"save":_vm.handleIcon}}):(!['expiredDate', 'description'].includes(field.id))?_c('v-text-field',{attrs:{"label":field.label,"rounded":"","rules":[field.rule],"background-color":"#f2f2f2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}):(field.id == 'description')?_c('v-textarea',{attrs:{"rounded":"","auto-grow":"","background-color":"#f2f2f2","label":field.label,"rows":1,"rules":[field.rule],"counter":150},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}):_c('v-menu',{staticStyle:{"background":"#f2f2f2"},attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":field.label,"persistent-hint":"","readonly":"","rounded":"","min":"","background-color":"#f2f2f2","append-icon":"mdi-calendar"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.minDate,"locale":"es"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],1)}),0)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }